<template>
  <v-card outlined class="pa-4">
    <!-- <p class="text-center font-weight-bold text-15 t-1 mb-0">
        Formas de pagamento
      </p> -->

    <div class="d-flex align-center flex-wrap gap-x-8 gap-y-4">
      <div
        v-for="paymentMethod in paymentMethodsIcons"
        :key="paymentMethod.title"
      >
        <p class="text--caption font-weight-medium mb-0">
          {{ paymentMethod.title }}
        </p>
        <div class="d-flex flex-wrap align-center gap-1">
          <img
            v-for="(icon, i) in paymentMethod.icons"
            :key="i"
            :src="icon.icon"
            :alt="icon.name"
            width="38px"
            height="22px"
            class="unselectable non-draggable"
          />
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
const icons = require.context("@/assets/images/payment-card/", false, /\.svg$/);
export default {
  props: {
    disallowOnlinePaymentMethods: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    icons,
    paymentMethods: [
      { title: "Débito", icons: ["pix"], paymentMethod: "PIX" },
      {
        title: "Crédito",
        paymentMethod: "CREDIT_CARD",
        icons: [
          "visa",
          "mastercard",
          "elo",
          "american-express",
          "hiper",
          "hipercard",
          "discover",
          "diners-club",
        ],
      },
    ],
  }),
  methods: {},
  computed: {
    paymentMethodsIcons() {
      return this.paymentMethods
        .filter(
          (pm) => !this.disallowOnlinePaymentMethods.includes(pm.paymentMethod)
        )
        .map((pm) => {
          return {
            ...pm,
            icons: pm.icons.map((icon) => {
              return {
                name: icon,
                icon: this.icons(`./${icon}.svg`),
              };
            }),
          };
        });
    },
  },
};
</script>

<style></style>
