<template>
  <div>
    <h5 v-if="title" class="mb-0">{{ title }}</h5>

    <vue-perfect-scrollbar
      :settings="{
        suppressScrollY: false,
        wheelPropagation: false,
      }"
      class="w-full"
      :class="{ 'mx-3': !noMargin }"
      ref="miniatures"
    >
      <div>
        <div class="d-flex gap-3">
          <div
            v-for="product in mappedProducts"
            :key="product.id"
            class="d-flex"
            :ref="`miniature-${product.id}`"
          >
            <div style="position: relative" class="mb-6 mt-2">
              <v-img
                :src="product.image"
                width="100"
                height="100"
                class="rounded-lg"
                @click="productDetails(product)"
                style="cursor: pointer"
              >
                <template v-slot:placeholder>
                  <v-card elevation="0" class="h-full">
                    <v-skeleton-loader
                      type="image"
                      class="rounded-lg"
                      height="100%"
                    />
                  </v-card>
                </template>
              </v-img>
              <v-chip
                v-if="productCartQuantity[product.id]"
                small
                color="primary"
                style="position: absolute; top: -8px; right: -10px"
                @click="productDetails(product)"
              >
                <v-icon x-small left>mdi-cart</v-icon>
                {{ productCartQuantity[product.id] || 0 }}
              </v-chip>
              <div
                class="text-center mx-3"
                style="position: absolute; bottom: -20px; left: 0; right: 0"
              >
                <v-card class="rounded-lg" style="height: auto">
                  <div class="d-flex flex-column pa-1 px-2 align-start">
                    <small class="lh-1" v-if="product.price.startsAt">
                      A partir de
                    </small>
                    <small class="lh-1" v-else>Por</small>
                    <p class="font-weight-bold mb-0 lh-1">
                      {{ product.price.amount | currency(true) }}
                    </p>
                  </div>
                </v-card>
                <!-- <p class="mb-0 lh-1 text-end">
                  <small class="lh-1" v-if="product.price.startsAt">
                    A partir de
                  </small>
                  <small class="lh-1" v-else>Por</small>
                  <span class="font-weight-bold">
                    {{ product.price.amount | currency(true) }}
                  </span>
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
    productCart: {
      type: Object,
      default: () => ({}),
    },
    products: {
      type: Array,
      required: true,
    },
    title: {
      type: String | Boolean,
      default: "Produtos",
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    productDetails(product) {
      this.centralizeMiniature(product.id);
      this.$root.$emit("product-details-modal", product);
    },
    productPrice(product) {
      const priceSet = new Set(
        product.Variants.map((variant) => variant.price)
      );
      const startsAt = priceSet.size > 1;
      const amount = Math.min(...priceSet);

      return { startsAt, amount };
    },
    centralizeMiniature(i) {
      const [miniature] = this.$refs[`miniature-${i}`];
      if (!miniature) return;

      const container = this.$refs.miniatures.$el;
      const scrollLeft =
        miniature.offsetLeft -
        container.offsetWidth / 2 +
        miniature.offsetWidth / 2;
      container.scrollTo({
        left: scrollLeft,
      });
    },
  },
  computed: {
    mappedProducts() {
      return this.products.map((product) => {
        return {
          ...product,
          price: this.productPrice(product),
        };
      });
    },
    productCartQuantity() {
      return Object.values(this.productCart).reduce(
        (acc, { quantity, product }) => {
          if (!acc[product.id]) acc[product.id] = 0;
          acc[product.id] += quantity;
          return acc;
        },
        {}
      );
    },
  },
};
</script>

<style>
</style>