<template>
  <div>
    <v-card outlined rounded="lg">
      <v-card-text>
        <v-btn
          class="float-right ml-1"
          :to="{
            name: 'app.payment.details',
            params: { id: pendingPayment.Payment.id },
            hash: '#pay',
          }"
          color="error"
          v-if="pendingPayment"
        >
          Pagar
        </v-btn>
        <div class="d-flex flex-column">
          <span class="mb-1 font-weight-bold text-18">
            Meu{{ tickets.length != 1 ? "s" : "" }} Ingresso{{
              tickets.length != 1 ? "s" : ""
            }}
          </span>
          <span class="mb-0 text-16">{{ statusMap[status].text }}</span>
        </div>
        <v-data-iterator
          :items="tickets"
          item-key="id"
          :page.sync="page"
          :items-per-page="6"
          :hide-default-footer="true"
        >
          <template v-slot:default="{ items }">
            <v-row
              v-if="!pendingPayment"
              class="mx-0"
              align-content="stretch"
              dense
            >
              <v-col
                cols="12"
                md="6"
                lg="4"
                v-for="(ticket, i) of items"
                :key="i"
              >
                <router-link
                  :to="{
                    name: 'app.ticket.details',
                    params: { id: ticket.id },
                  }"
                >
                  <v-alert
                    :border="ticket.TicketEntry ? undefined : 'left'"
                    text
                    class="mb-0 pr-2 h-full"
                    :color="ticketStatusLabel[ticket.Payment.status].color"
                    style="cursor: pointer"
                    v-ripple
                    dense
                  >
                    <div class="d-flex gap-3 pt-xl-0">
                      <p
                        class="mb-0 text-23 font-weight-black"
                        v-if="status != 'pending' && tickets.length > 1"
                      >
                        {{ i + 1 + (page * 6 - 6) }}.
                      </p>
                      <div class="flex-grow-1">
                        <v-chip
                          class="float-right"
                          label
                          x-small
                          style="cursor: pointer"
                          :color="
                            ticketStatusLabel[ticket.Payment.status].color
                          "
                        >
                          <v-icon x-small left>
                            {{ ticketStatusLabel[ticket.Payment.status].icon }}
                          </v-icon>
                          {{ ticketStatusLabel[ticket.Payment.status].text }}
                        </v-chip>
                        <p class="mb-0 text-14 lh-1 font-weight-bold">
                          {{ ticket.TicketBlock.TicketGroup.name }}
                        </p>
                        <b class="mb-0 text-12 lh-1 font-weight-medium">
                          {{ ticket.TicketBlock.name }}
                        </b>
                      </div>

                      <v-chip
                        v-if="ticket.TicketEntry"
                        label
                        x-small
                        class="ml-1"
                      >
                        Usado
                      </v-chip>
                    </div>
                  </v-alert>
                </router-link>
              </v-col>
            </v-row>
          </template>
          <template v-slot:footer v-if="tickets.length > 6">
            <div
              class="d-flex align-end justify-content-center mx-1 mt-2"
            >
              <span class="disabled--text lh-1">
                Página {{ page }} de {{ numberOfPages }}
              </span>
              <v-spacer />
              <v-btn
                icon
                small
                depressed
                class="mr-1"
                @click="formerPage"
                :disabled="page == 1"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                depressed
                class="ml-1"
                @click="nextPage"
                :disabled="page == numberOfPages"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";

export default {
  data: () => ({
    ticketStatusLabel: PAYMENT.ticketStatus,
    page: 1,
  }),
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.tickets.length / 6);
    },
    status() {
      const hasPending = this.tickets.some((ticket) => {
        return PAYMENT.status_db_groups.pending.includes(ticket.Payment.status);
      });
      if (hasPending) return "pending";
      return "approved";
    },
    pendingPayment() {
      if (this.status !== "pending") return false;
      return this.tickets.find((ticket) =>
        PAYMENT.status_db_groups.pending.includes(ticket.Payment.status)
      );
    },
    statusMap() {
      return {
        pending: {
          color: "warning",
          icon: "mdi-clock-outline",
          text: "Você possui uma venda pendente de pagamento!",
        },
        approved: {
          text: `Você tem ${
            this.tickets.length == 1 ? "um" : this.tickets.length
          } ingresso${this.tickets.length == 1 ? "" : "s"} para o evento!`,
          color: "success",
        },
      };
    },
  },
  props: {
    tickets: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
