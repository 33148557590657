export default {
  paymentMethod: {
    OFFLINE: { text: "Externo" },
    MERCADOPAGO: { text: "Mercado Pago" },
    PAGSEGURO: { text: "PagSeguro" },
    COURTESY: { text: "Cortesia" },
    POS: { text: "PDV" },
  },
  paymentType: {
    UNKNOWN: { text: "Desconhecido", color: "#333" },
    DEBIT_CARD: { text: "Cartão de Débito", color: "#FFC500" },
    CREDIT_CARD: { text: "Cartão de Crédito", color: "#7c65ff" },
    PIX: { text: "Pix", color: "#00bdae" },
    PAYPAL: { text: "PayPal", color: "#273469" },
    BANK_TRANSFER: { text: "Transferência Bancária", color: "#ff5b5b" },
    COURTESY: { text: "Cortesia", color: "#fe6fff" },
    MONEY: { text: "Dinheiro", color: "#4caf50" },
  },
  ticketStatus: {
    succeeded: { text: "Aprovado", color: "success", icon: "mdi-check" },
    canceled: { text: "Cancelado", color: "info", icon: "mdi-close" },
    refunded: { text: "Reembolsado", color: "info", icon: "mdi-credit-card-refund" },
    disputed: { text: "Disputa", color: "error", icon: "mdi-alert" },
    rejected: { text: "Rejeitado", color: "error", icon: "mdi-credit-card-remove" },
    pending: { text: "Pendente", color: "warning", icon: "mdi-credit-card-clock" },
    requires_payment_method: { text: "Aguardando Pagamento", color: "warning", icon: "mdi-alert" },
    requires_confirmation: { text: "Aguardando Confirmação", color: "warning", icon: "mdi-credit-card-sync" },
    requires_action: { text: "Aguardando Ação", color: "warning", icon: "mdi-credit-card-sync" },
    processing: { text: "Processando", color: "warning", icon: "mdi-credit-card-clock" },
    requires_capture: { text: "Aprovado (Não Capturado)", color: "success", icon: "mdi-check" },
  },

  status_db_groups: {
    approved: ["succeeded"],
    canceled: ["canceled", "refunded", "disputed"],
    pending: [
      "pending",
      "requires_payment_method",
      "requires_confirmation",
      "requires_action",
      "processing",
      "requires_capture",
      "rejected",
    ],
    pending_action: ["requires_action"],
  }
};
