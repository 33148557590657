<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="900"
      contentClass="rounded-xl elevation-0"
      :overlay-opacity="0.9"
      persistent
      :scrollable="!products || !products.length"
    >
      <!-- Confirm ticket group and ticket block to finish sale -->
      <div
        class="d-flex align-center justify-center gap-8 v-dialog--scrollable"
      >
        <v-card
          v-if="cart"
          class="flex-grow-1 flex-shirk-0 overflow-hidden rounded-xl"
          style="max-width: 450px"
        >
          <v-img
            :src="party.cover"
            :aspect-ratio="$vuetify.breakpoint.smAndDown ? 22 / 9 : 16 / 9"
          />
          <v-card-text class="pt-1">
            <span class="text-overline lh-1">
              {{ party.date | date("DD [de] MMMM - HH:mm") }}
            </span>
            <h5>{{ party.name }}</h5>
            <div class="d-block d-md-none pb-5">
              <BuyTicketCart
                :cartList="cartProcessed.list"
                :totalValue="cartProcessed.totalValue"
                :party="party"
                :productCart="productCart"
              />
              <div v-if="products && products.length" class="mt-5">
                <h6 class="text-overline font-weight-bold lh-1 text-18 mb-0">
                  Compre junto
                </h6>
                <div class="w-full">
                  <product-row-select
                    :products="products"
                    :party="party"
                    :title="false"
                    :productCart="productCart"
                    no-margin
                  />
                </div>
              </div>
            </div>

            <v-alert color="primary" text dense class="mb-0">
              Ao clicar em "<i>Comprar</i>",
              {{
                cartProcessed.totalValue.quantity > 1
                  ? "os ingressos serão reservados"
                  : "o ingresso será reservado"
              }}
              para você. Finalize seu pagamento dentro de 30 minutos para
              garantir sua reserva.
            </v-alert>
            <v-alert
              v-if="!allowTransfer || ticketBlock.canUseMembership"
              dense
              type="info"
              text
              border="bottom"
              class="mb-0 mt-2"
            >
              <p v-if="ticketBlock.canUseMembership" class="mb-1 lh-4">
                Você está comprando um ingresso com
                <b class="font-weight-bold">desconto de sócio</b>. O desconto é
                válido apenas para a compra de um ingresso.
              </p>
              <p v-if="!allowTransfer" class="mb-1 lh-4">
                O ingresso é
                <b class="font-weight-bold">pessoal e intransferível</b>. Será
                necessário apresentar um documento com foto para entrar no
                evento.
              </p>
            </v-alert>
            <v-scroll-y-transition hide-on-leave>
              <v-alert
                v-if="error"
                type="error"
                text
                border="left"
                class="mb-0 mt-2"
              >
                {{ error }}
              </v-alert>
            </v-scroll-y-transition>
          </v-card-text>

          <!-- Actions -->
          <v-card-actions class="align-end justify-space-between">
            <v-btn text @click="close" :disabled="loading"> Cancelar </v-btn>
            <v-btn
              color="primary"
              @click="runRecaptcha"
              large
              :loading="loading"
            >
              Comprar •
              {{ cartProcessed.totalValue.total | currency(true) }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <div
          class="d-flex flex-column gap-4 flex-grow-1"
          style="max-width: 375px"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-card class="d-none d-md-block rounded" style="max-width: 375px">
            <BuyTicketCart
              scrollable
              class="pa-4"
              :style="{
                maxHeight: `calc(100vh - ${
                  96 + (products && products.length ? 190 : 0)
                }px)`,
              }"
              :cartList="cartProcessed.list"
              :totalValue="cartProcessed.totalValue"
              :party="party"
              :productCart="productCart"
            />
          </v-card>
          <v-card
            v-if="products && products.length"
            class="d-none d-md-block rounded"
            style="max-width: 375px"
          >
            <v-card-title>
              <h6 class="text-overline font-weight-bold lh-1 text-18 mb-0">
                Compre junto
              </h6>
            </v-card-title>
            <v-card-text class="pb-3">
              <div class="w-full">
                <product-row-select
                  :products="products"
                  :party="party"
                  :title="false"
                  :productCart="productCart"
                  no-margin
                />
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-dialog>
    <re-captcha
      v-if="dialog"
      ref="recaptcha"
      @success="buyTicket"
      @error="recaptchaError"
      @close="recaptchaError"
    />
  </div>
</template>

<script>
import SHOP from "@/services/shop";
import ReCaptcha from "@/components/global/ReCaptcha.vue";
import BuyTicketCart from "./BuyTicketCart.vue";
import { process } from "@/utils/shop/cart";
import ProductRowSelect from "../ProductRowSelect.vue";

export default {
  components: { ReCaptcha, BuyTicketCart, ProductRowSelect },
  data: () => ({
    loading: false,
    error: false,
    dialog: false,
    ticketBlock: false,
    ticketGroup: false,
    code: null,
  }),
  methods: {
    runRecaptcha() {
      this.loading = true;
      this.error = null;
      this.$refs.recaptcha.execute();
    },
    recaptchaError() {
      this.loading = false;
      this.error = "Erro ao validar o captcha";
    },
    async buyTicket(recaptcha) {
      try {
        const cart = Object.fromEntries(
          Object.entries(this.cart)
            .map(([key, value]) => [
              key,
              {
                quantity: value.quantity,
                tables: value.tables.map((table) => table.id),
              },
            ])
            .filter(([key, value]) => value.quantity > 0)
        );
        const { ticket } = await SHOP.buyTicket(
          this.$route.params.orgSlug,
          this.$route.params.partyId,
          {
            cart,
            recaptcha,
            productCart: this.productCart,
            amount: this.cartProcessed.totalValue.total,
            coupon: this.coupon?.coupon?.Codes[0].code || null,
          },
          { code: this.code, seller: this.seller?.id || undefined }
        );

        this.$root.$emit("initiate_checkout", {
          amount: this.cartProcessed.totalValue.total,
          cart: this.cart,
        });

        this.$router.push({
          hash: "#pay",
          name: "app.payment.details",
          params: { id: ticket.paymentId },
        });
      } catch (err) {
        this.loading = false;
        this.error = err.message || "Ocorreu um erro ao processar o pedido";
      }
    },
    open({ code, cart, coupon }) {
      this.cart = cart;
      this.code = code;
      this.coupon = coupon;
      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
    },
  },

  computed: {
    cartProcessed() {
      return process(this.cart, this.productCart);
    },
    allowTransfer() {
      return !this.cartProcessed.list.some(
        ({ ticketBlock }) => !ticketBlock.allowTransfer
      );
    },
  },
  props: {
    cart: {
      type: Object,
      default: () => ({}),
    },
    productCart: {
      type: Object,
      default: () => ({}),
    },
    party: {
      type: Object | null,
      required: true,
    },
    seller: {
      type: Object | null,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.$root.$on("buy-ticket", this.open);
  },
};
</script>

<style></style>
