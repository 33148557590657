<template>
  <div>
    <v-slide-y-transition leave-absolute v-if="!!error">
      <v-card :loading="loading">
        <error-handler v-if="!!error" :error="error" />
      </v-card>
    </v-slide-y-transition>
    <template v-if="!error">
      <party-mobile
        v-if="$vuetify.breakpoint.xsOnly || forceSmall"
        :data="computedData"
        :code="code"
        :loading="loading"
        @refresh="getParty()"
        @changeParty="selectedParty = $event"
      />
      <party-desktop
        v-else
        :data="computedData"
        :code="code"
        :loading="loading"
        @refresh="getParty()"
        @changeParty="selectedParty = $event"
      />
    </template>
    <fb-pixel
      v-if="data?.party?.fbPixelId"
      :fbPixelId="data?.party?.fbPixelId"
    />
  </div>
</template>

<script>
import SHOP from "@/services/shop";

import PartyMobile from "./PartyMobile.vue";
import PartyDesktop from "./PartyDesktop.vue";
import ErrorHandler from "./ErrorHandler.vue";
import FbPixel from "../../components/global/FbPixel.vue";

export default {
  metaInfo() {
    return {
      title: this.titlePage,
      script: this.partySchema
        ? [
            {
              type: "application/ld+json",
              json: this.partySchema,
            },
          ]
        : [],
    };
  },
  components: {
    PartyMobile,
    PartyDesktop,
    ErrorHandler,
    FbPixel,
  },
  data: () => ({
    code: null,
    seller: null,

    loading: true,
    error: null,
    data: null,
    forceSmall: false,
    selectedParty: null,
    coupon: null,
  }),
  methods: {
    applyCoupon(coupon) {
      this.coupon = coupon;
      this.getParty(false);
    },
    async getParty(loading = true) {
      try {
        this.loading = loading;
        console.log(this.seller);
        let response = await SHOP.party(
          this.$route.params.orgSlug,
          this.$route.params.partyId,
          {
            code: this.code,
            seller: this.seller,
            coupon: this.coupon,
          }
        );

        const org = response?.organization;
        if (org?.Platform) {
          const uri = `//${org.Platform.domain}/shop/${org.slug}/${this.$route.params.partyId}`;
          window.location.href = uri;
          return;
        }

        this.data = response;

        this.loading = false;
      } catch (error) {
        this.error = error;
        this.loading = false;
      }
    },
    manageCode() {
      try {
        var sessionStorageItem = JSON.parse(
          window.sessionStorage.getItem("shop-code") || "{}"
        );

        if (this.$route.hash) {
          this.code = this.$route.hash.replace("#", "");

          window.sessionStorage.setItem(
            "shop-code",
            JSON.stringify({
              ...sessionStorageItem,
              [this.$route.params.partyId]: this.code,
            })
          );

          this.$router.push({ hash: null });
        } else {
          this.code = sessionStorageItem[this.$route.params.partyId] || null;
        }
      } catch (e) {
        console.log(e);
        window.sessionStorage.removeItem("shop-code");
      }
    },
    manageSeller() {
      try {
        var sessionStorageItem = JSON.parse(
          window.sessionStorage.getItem("shop-seller") || "{}"
        );

        if (this.$route?.query?.seller) {
          sessionStorageItem = sessionStorageItem || {};
          this.seller = this.$route.query.seller;

          window.sessionStorage.setItem(
            "shop-seller",
            JSON.stringify({
              ...sessionStorageItem,
              [this.$route.params.partyId]: this.seller,
            })
          );
        } else {
          this.seller = sessionStorageItem[this.$route.params.partyId] || null;
        }
      } catch (e) {
        console.log(e);
        window.sessionStorage.removeItem("shop-seller");
      }
    },
    removeSeller() {
      var sessionStorageItem = JSON.parse(
        window.sessionStorage.getItem("shop-seller") || "{}"
      );

      delete sessionStorageItem[this.$route.params.partyId];

      window.sessionStorage.setItem(
        "shop-seller",
        JSON.stringify(sessionStorageItem)
      );

      this.seller = null;
      this.getParty();
    },
  },
  computed: {
    computedData() {
      if (!this.selectedParty) return this.data;
      const party = this.data.party?.ChildParties.find(
        (p) => p.id == this.selectedParty
      );
      if (!party) return this.data;

      return {
        ...this.data,
        baseParty: this.data.party,
        party: {
          ...this.data.party,
          name: party.name,
          subtitle: party.subtitle,
          cover: party.cover,
          date: party.date,
          endDate: party.endDate,
          description: party.description,
          spotifyId: party.spotifyId,
          youtubeId: party.youtubeId,
        },
      };
    },
    ticketPrice() {
      if (!this.data?.ticketGroups) return null;
      const in_stock = this.data.ticketGroups.some((tg) =>
        tg.ticketBlocks.some((t) => t.status == "available")
      );
      if (!in_stock) return false;
      return this.data.ticketGroups.reduce((acc, tg) => {
        const price = tg.ticketBlocks.reduce((acc, t) => {
          if (t.status == "available" && t.price < acc) return t.price;
          return acc;
        }, Infinity);
        if (price < acc) return price;
        return acc;
      }, Infinity);
    },
    titlePage() {
      return this.data?.party?.name || "Shop - Evento";
    },
    partySchema() {
      if (!this.data?.party) return null;
      return {
        "@context": "http://schema.org",
        "@type": "Event",
        eventStatus: "https://schema.org/EventScheduled",
        eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
        name: this.data.party.name,
        description: this.data.party.description,
        startDate: this.data.party.date,
        endDate: this.data.party.endDate,
        location: {
          "@type": "Place",
          name: this.data.party.Address?.name,
          address: {
            "@type": "PostalAddress",
            streetAddress: this.data?.party?.Address?.address,
            addressLocality: this.data?.party?.Address?.city,
            addressRegion: this.data?.party?.Address?.state,
            postalCode: this.data?.party?.Address?.zipCode,
            addressCountry: this.data?.party?.Address?.country,
          },
        },
        image: this.data?.party?.cover,
        offers: {
          "@type": "Offer",
          url: window.location.href,
          price: this.ticketPrice,
          priceCurrency: "BRL",
          availability:
            "http://schema.org/" + ticketPrice ? "InStock" : "SoldOut",
        },
        organizer: {
          "@type": "Organization",
          name: this.data?.organization?.name,
          url:
            window.location.origin + "/shop/" + this.data?.organization?.slug,
        },
      };
    },
  },
  watch: {
    "$route.params.partyId": {
      handler() {
        if (this.selectedParty) return;
        this.data = null;
        this.getParty(true);
      },
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.xsOnly) this.forceSmall = true;

    this.manageCode();
    this.manageSeller();
    this.getParty();

    this.$root.$on("refresh-party", () => this.getParty());
    this.$root.$on("remove-seller", () => this.removeSeller());
    this.$root.$on("applyCoupon", (coupon) => this.applyCoupon(coupon));
  },
};
</script>

<style></style>
