<template>
  <div>
    <!-- Coupon -->
    <v-alert
      :color="couponData && couponData?.valid ? 'success' : 'info'"
      text
      icon="mdi-ticket-percent"
      dense
      class="mt-4 mx-1 mb-0"
      @click="openModal"
      style="cursor: pointer"
    >
      <div
        v-if="couponData && couponData?.valid"
        class="d-flex align-center justify-space-between"
      >
        <span>Cupom aplicado: {{ couponData?.coupon?.name }}</span>
        <v-btn icon x-small :loading="loading" @click.stop="removeCoupon()">
          <v-icon color="warning">mdi-close</v-icon>
        </v-btn>
      </div>
      <template v-else>
        Você possui um cupom de desconto? Clique aqui para aplicar.
      </template>
    </v-alert>
    <v-dialog v-model="dialog" max-width="400" :persistent="loading">
      <v-card class="pa-4" rounded="lg">
        <h5 class="text-center mb-4">Aplicar cupom de desconto</h5>
        <v-text-field
          v-model="couponCode"
          label="Cupom de desconto"
          outlined
          hide-details
          class="mb-3"
          :disabled="loading"
        />
        <v-alert v-if="error" type="error" text dense>
          {{ error }}
        </v-alert>
        <div class="d-flex">
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            :disabled="loading"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="applyCoupon"
            :loading="loading"
            :disabled="loading || !couponCode.length"
          >
            Aplicar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      error: false,
      success: false,
      couponCode: "",
      couponData: null,
    };
  },
  watch: {
    dialog(val) {
      this.couponCode = "";
    },
    couponCode(val) {
      this.error = false;
      this.couponCode = val.toUpperCase();
    },
    coupon() {
      this.couponData = this.coupon;
    },
    couponData(val) {
      this.loading = false;
      if (!val) return;
      if (!this.coupon.valid) this.error = this.coupon.message;
      else this.dialog = false;
    },
  },
  methods: {
    openModal() {
      if (this.couponData && this.couponData?.valid) {
        this.removeCoupon();
        return;
      }
      this.dialog = true;
    },
    removeCoupon() {
      this.loading = true;
      this.$root.$emit("applyCoupon", null);
    },
    applyCoupon() {
      if (!this.couponCode || this.couponCode.length < 3) {
        this.error = "Cupom inválido";
        return;
      }

      this.couponData = null;
      this.loading = true;
      this.$root.$emit("applyCoupon", this.couponCode);
    },
  },
  props: {
    coupon: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>